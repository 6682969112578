<template>
  <div :class="$style.mainContainer">
    <Slant :class="$style.slant" :color="$style.colorSlant">
      <div :class="$style.mainContainerInner">
        <div :class="$style.columnText">
          <div :class="$style.headlineContainer">
            <TransitionOnScroll
              :translateY="isMqLarger ? 20 : 10"
              rootMargin="0px"
              @intersecting="isTextIntersecting = $event"
            >
              <BeforeHeading :class="$style.beforeTitle">{{ $t('beforeTitle') }}</BeforeHeading>
            </TransitionOnScroll>
            <TransitionOnScroll
              :translateY="isMqLarger ? 35 : 15"
              rootMargin="0px"
              :isIntersecting="isTextIntersecting"
            >
              <Heading :tag="HEADINGS.h1"> {{ $t('title') }} </Heading>
            </TransitionOnScroll>
            <TransitionOnScroll
              :translateY="isMqLarger ? 60 : 28"
              rootMargin="0px"
              :isIntersecting="isTextIntersecting"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="$t('heroText')"></span>
            </TransitionOnScroll>
            <TransitionOnScroll
              :class="$style.heroButtonWrap"
              :translateY="isMqLarger ? 90 : 50"
              rootMargin="0px"
              :isIntersecting="isTextIntersecting"
            >
              <Button
                :class="$style.heroButton"
                :shape="BUTTON_STYLES.shape.spade"
                :to="
                  localeRoute({
                    name: ROUTE_NAMES.auth,
                    params: { type: AUTH_TYPES.signup },
                  })
                "
              >
                {{ $t('form.start') }}
              </Button>
            </TransitionOnScroll>
          </div>
        </div>
        <div :class="$style.columnImg">
          <div :class="$style.imageContainer">
            <div :class="$style.playContainer">
              <div
                :class="[
                  $style.playCircleHover,
                  { [$style.playCircleHoverVisible]: isPlayButtonIntersecting },
                ]"
                @click="showOverlayHomeVideo"
              ></div>
              <div :class="$style.playCircle">
                <TransitionOnScroll
                  :class="$style.playCircleBefore"
                  :translateY="0"
                  :scale="0.8"
                  rootMargin="0px 0px -5% 0px"
                  @intersecting="isPlayButtonIntersecting = $event"
                ></TransitionOnScroll>
                <TransitionOnScroll
                  :class="$style.playLabelContainer"
                  :transformStart="`${$style.playLabelContainerTranslate} rotate(-30deg)`"
                  :isIntersecting="isPlayButtonIntersecting"
                >
                  <span
                    v-for="(charStyle, index) in playLabelSettings.styles"
                    :key="`char-${index}`"
                    :class="$style.playChar"
                    :style="{
                      transform: `rotate(${charStyle.radius}rad)`,
                      width: `${charStyle.width}px`,
                      height: `${charStyle.height}px`,
                      left: `${charStyle.left}px`,
                      top: `${charStyle.top}px`,
                    }"
                    >{{ playLabelSettings.label[index] }}</span
                  >
                </TransitionOnScroll>
                <IconPlay
                  :class="[$style.playIcon, { [$style.playIconVisible]: isPlayButtonIntersecting }]"
                />
              </div>
            </div>
            <div :class="$style.imageContainerInner">
              <TransitionOnScroll
                :class="[$style.imageBackground, { [$style.intersecting]: isImgBgIntersecting }]"
                :translateY="0"
                rootMargin="0px 0px -5% 0px"
                @intersecting="isImgBgIntersecting = $event"
              ></TransitionOnScroll>
              <IconHeroShape :class="$style.imageShape" />
            </div>
          </div>
        </div>
      </div>
    </Slant>
    <div :class="$style.productionTimeContainer">
      <TransitionOnScroll
        :class="$style.productionTimeContainerInner"
        :translateY="40"
        :rootMargin="isMqLarger ? '0px' : '0px 0px -10% 0px'"
      >
        <div :class="$style.productionTimeTitle">{{ $t('productionTime') }}</div>
        <div :class="$style.productionTimeBlock">{{ productionTime[0] }}</div>
        <div :class="$style.productionTimeBlock">{{ productionTime[1] }}</div>
        <div :class="$style.productionTimeSubTitle">{{ $t('days') }}</div>
      </TransitionOnScroll>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { onLoad } from '@/helpers/events'
import { ROUTE_NAMES, AUTH_TYPES } from '@/constants/route-names'
import Slant from '@/components/Slant/Slant.vue'
import BeforeHeading from '@/components/Heading/BeforeHeading.vue'
import Heading, { HEADINGS } from '@/components/Heading/Heading.vue'
import Button, { BUTTON_STYLES } from '@/components/Form/Button.vue'
import TransitionOnScroll from '@/components/TransitionOnScroll.vue'
import IconHeroShape from '@/components/_icons/IconHeroShape.svg'
import IconPlay from '@/components/_icons/IconPlay.svg'
import { PLAY_LABEL_CHAR } from './play-label-char'

const PRODUCTION_TIME_IN_DAYS = 7

export default {
  name: 'HomeHero',
  components: {
    BeforeHeading,
    Button,
    Heading,
    IconHeroShape,
    IconPlay,
    Slant,
    TransitionOnScroll,
  },

  data() {
    return {
      AUTH_TYPES,
      BUTTON_STYLES,
      HEADINGS,
      isImgBgIntersecting: false,
      isPlayButtonIntersecting: false,
      isTextIntersecting: false,
      PLAY_LABEL_CHAR,
      ROUTE_NAMES,
    }
  },

  computed: {
    ...mapGetters({
      isMqLarger: 'viewport/isMqLarger',
    }),

    playLabelSettings() {
      return PLAY_LABEL_CHAR[this.$i18n.locale] || PLAY_LABEL_CHAR.en
    },

    productionTime() {
      const str = String(PRODUCTION_TIME_IN_DAYS)
      return str.length === 1 ? `0${str}` : str
    },
  },

  mounted() {
    onLoad().then(this.preloadOverlayHomeVideo)
  },

  methods: {
    ...mapActions({
      preloadOverlayHomeVideo: 'overlay/preloadOverlayHomeVideo',
      showOverlayHomeVideo: 'overlay/showOverlayHomeVideo',
    }),
  },
}
</script>

<style lang="scss" module>
@import '@/components/Header/header-settings';
@import '@/components/Heading/heading-settings';
@import '@/components/Slant/slant-settings';

$container-margin-bottom-1: 35px;
$container-margin-bottom-2: 65px;
$container-margin-bottom: $container-margin-bottom-1 + $container-margin-bottom-2;

$column-text-width-large: 100vw;
$column-text-width-larger: 44vw;
$column-text-width-full: 50vw;
$column-img-width-small: 100vw;
$column-img-width-large: 82vw;
$column-img-width-larger: 100vw - $column-text-width-larger;
$column-img-width-full: 100vw - $column-text-width-full;

$image-container-translate-x: 6vw;
$image-container-translate-x-full: 4vw;
$image-bubble-ratio: round((634 / 773) * 10000) / 10000;

$play-circle-size: 62px;
$play-label-container-translate: translate(-33px, -32px);
$half-slant-height: calc(100vw * #{$slant-ratio} / 2);
$production-time-block-width-large: 52px;
$production-time-block-width-small: 46px;
$production-time-block-height-large: 82px;
$production-time-block-height-small: 74px;

:export {
  colorSlant: $color-white;
  playLabelContainerTranslate: $play-label-container-translate;
}

.mainContainer {
  display: flex;
  position: relative;
  margin-top: $site-header-height-small;
  min-height: calc(100vh - #{$site-header-height-small + $container-margin-bottom-1});

  @include min-screen($mq-large) {
    margin-top: $site-header-height;
    min-height: calc(100vh - #{$site-header-height + $container-margin-bottom-1});
  }
}

.slant {
  display: flex;
  width: 100%;
}

.mainContainerInner {
  margin-bottom: $container-margin-bottom-2;
  width: 100%;
  min-height: 700px;

  @include min-screen($mq-larger) {
    display: flex;
  }
}

.columnText,
.columnImg {
  position: relative;

  @include min-screen($mq-larger) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.columnText {
  width: $column-text-width-large;
  z-index: 4;

  @include min-screen($mq-larger) {
    width: $column-text-width-larger;
  }

  @include min-screen($mq-full) {
    width: $column-text-width-full;
  }
}

.columnImg {
  overflow: hidden;
  padding-bottom: 80px;
  width: $column-img-width-small;
  z-index: 2;

  @include min-screen($mq-medium) {
    padding-bottom: 120px;
  }

  @include min-screen($mq-large) {
    margin-left: auto;
    width: $column-img-width-large;
  }

  @include min-screen($mq-larger) {
    margin-left: 0;
    padding-bottom: 0;
    width: $column-img-width-larger;
  }

  @include min-screen($mq-full) {
    width: $column-img-width-full;
  }
}

.headlineContainer {
  padding: 46px $header-padding-left-x-small 50px;
  max-width: 580px;
  box-sizing: content-box;

  @include min-screen($mq-small) {
    padding: 60px #{$header-padding-left-x-small + 10px};
  }

  @include min-screen($mq-medium) {
    padding: calc(6.5vw + 25px) calc(12vw - 20px) calc(6.5vw + 20px);
  }

  @include min-screen($mq-large) {
    padding: 70px 15px 20px 74px;
  }

  @include min-screen($mq-larger) {
    margin: auto;
    padding-left: $header-padding-left-x-full;
    transform: translateY(-3%);
  }

  @include min-screen($mq-full) {
    padding-left: calc(28vw - 253px);
    padding-right: 50px;
  }

  @include min-screen($mq-high) {
    padding-left: 120px;
  }
}

.heroButtonWrap {
  margin-top: 25px;
}

.playContainer {
  position: absolute;
  top: 37%;
  left: 17%; // 20%;
  transform: translateY(-50%) scale(0.8);
  z-index: 5;

  @include min-screen($mq-medium) {
    transform: translateY(-50%);
  }

  @include min-screen($mq-large) {
    top: 50%;
    left: calc(8% - 16px);
  }

  @include min-screen($mq-larger) {
    left: calc(11% - 16px);
  }

  @include min-screen($mq-full) {
    left: calc(9% - 16px);
  }
}

.playCircleHover {
  position: relative;
  width: $play-circle-size;
  height: $play-circle-size;
  cursor: pointer;
  transform: translateY(50%);
  z-index: 4;
}

.playCircleHoverVisible {
  & ~ .playCircle {
    .playCircleBefore {
      transition-duration: 0.3s;
    }
  }

  &:hover ~ .playCircle {
    .playCircleBefore {
      transform: scale(1.05);
    }
  }
}

.playCircle {
  position: relative;
  width: $play-circle-size;
  height: $play-circle-size;
  cursor: default;
  transform: translateY(-50%);
  z-index: 2;
}

.playCircleBefore {
  position: absolute;
  background-color: $color-brand-navy;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.playLabelContainer {
  position: relative;
  width: 64px;
  height: 63px;
  transform-origin: bottom right;
  transform: $play-label-container-translate;
}

.playChar {
  position: absolute;
  color: $color-brand-navy;
  font-size: $font-size-small;
  font-weight: bold;
  line-height: 0.65;
  white-space: pre;
  transform-origin: 50% 100%;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: calc(50% + 2px);
  width: 17px;
  color: $color-white;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s $transition-on-scroll-easing;
}

.playIconVisible {
  opacity: 1;
}

.imageContainer {
  margin: auto 0;
  width: 100%;
  box-sizing: content-box;
  color: $color-background;
  transform: translateY(-10px);

  @include min-screen($mq-medium) {
    height: calc(#{$column-img-width-small} * #{$image-bubble-ratio});
  }

  @include min-screen($mq-large) {
    height: calc(#{$column-img-width-large} * #{$image-bubble-ratio});
  }

  @include min-screen($mq-larger) {
    padding: 60px 0;
    height: calc(86vh - #{$site-header-height + $container-margin-bottom});
    min-height: calc(#{$column-img-width-larger} * #{$image-bubble-ratio});
    transform: translateY(-3%);
  }

  @include min-screen($mq-full) {
    min-height: calc(#{$column-img-width-full} * #{$image-bubble-ratio});
  }
}

.imageContainerInner {
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: translateX($image-container-translate-x);
  z-index: 1;

  @include min-screen($mq-full) {
    transform: translateX($image-container-translate-x-full);
  }
}

.imageBackground {
  position: absolute;
  background-image: url('./home-hero-woman-computer-800.jpg');
  background-position: right $image-container-translate-x top;
  background-repeat: no-repeat;
  background-size: auto 105%;
  top: 0;
  left: 2px;
  margin: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  z-index: 2;

  &.intersecting {
    background-size: auto 100%;
  }

  @include min-screen($mq-medium) {
    background-image: url('./home-hero-woman-computer-1180.jpg');
  }

  @include min-screen($mq-larger) {
    left: 6px;
    background-position: right #{$image-container-translate-x - 3vw} top;
  }

  @include min-screen($mq-full) {
    background-position: right $image-container-translate-x-full top;
  }
}

.imageShape {
  position: relative;
  width: 100%;
  height: 100%;
  vertical-align: top;
  z-index: 5;
}

.productionTimeContainer {
  position: absolute;
  left: 50%;
  bottom: $half-slant-height;
  color: $color-brand-navy;
  font-size: $h4-font-size-small;
  font-weight: bold;
  letter-spacing: $letter-spacing-uppercase;
  text-transform: uppercase;
  transform: translate(-50%, 50%);
  z-index: 5;

  @include min-screen($mq-medium) {
    font-size: $font-size-normal;
  }
}

.productionTimeContainerInner {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: $production-time-block-width-small * 2 + 12px;

  @include min-screen($mq-large) {
    width: $production-time-block-width-large * 2 + 14px;
  }
}

.productionTimeTitle,
.productionTimeSubTitle {
  position: absolute;
  text-align: center;
}

.productionTimeTitle {
  top: -16px;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, -100%);
}

.productionTimeBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  padding-left: 2px;
  width: $production-time-block-width-small;
  height: $production-time-block-height-small;
  border-radius: 6px;
  box-shadow: 5px 5px 7px 0px transparentize($color-black, 0.8);
  box-sizing: content-box;
  font-family: $font-family-heading;
  font-weight: bold;
  font-size: 45px;

  @include min-screen($mq-large) {
    width: $production-time-block-width-large;
    height: $production-time-block-height-large;
    font-size: 50px;
  }
}

.productionTimeSubTitle {
  bottom: -24px;
  left: 50%;
  transform: translate(-50%, 100%);
}
</style>

<i18n src="@/locales/home-hero.json" />
